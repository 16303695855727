import { Classifier } from '@alcs/services';

import { useSessionStorage } from './useSessionStorage';

const fileTypesSessionStorageKey = 'fileTypes';

export const useFileTypes = () => {
    const { value, setValue, isLoaded } = useSessionStorage<Classifier[]>(fileTypesSessionStorageKey);

    return {
        fileTypes: value,
        setFileTypes: setValue,
        areFileTypesLoaded: isLoaded,
    };
};
