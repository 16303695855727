import React, { Fragment, useEffect, useState } from 'react';
import { Spr_user_message_ot } from '@alcs/beans';
import { createDefaultI18n, useI18n } from '@alcs/i18n';
import { AlertColor } from '@mui/material';
import { AxiosError } from 'axios';
import isObject from 'lodash/isObject';

import { ErrorMessage } from './ErrorMessage';
import { useGwt } from './GwtContext';

export type ErrorFallbackProps = {
    error: unknown;
};

type DisplayableErrorMessage = {
    message: string;
    severity: AlertColor;
};

const sprSeverityToMuiSeverity: Record<string, AlertColor> = {
    W: 'warning',
    I: 'info',
    E: 'error',
    S: 'success',
};

const errorToMessages = (error: unknown, defaultErrorMessage: string): DisplayableErrorMessage[] => {
    if (
        isObject((error as AxiosError<{ messages: Spr_user_message_ot[] }>).response?.data?.messages) &&
        Array.isArray((error as AxiosError<{ messages: Spr_user_message_ot[] }>).response?.data?.messages)
    ) {
        return (error as Required<AxiosError<{ messages: Spr_user_message_ot[] }>>).response.data.messages.map(
            ({ default_text, severity }) => ({
                message: default_text ?? defaultErrorMessage,
                severity: sprSeverityToMuiSeverity[severity] ?? error,
            }),
        );
    }

    if (error instanceof Error) {
        return [
            {
                message: error.message,
                severity: 'error',
            },
        ];
    }

    return [
        {
            message: defaultErrorMessage,
            severity: 'error',
        },
    ];
};

export const defaultErrorFallbackI18n = createDefaultI18n('components.ErrorFallback', {
    defaultErrorMessage: 'A system error has occurred. Contact your system administrator',
});

export const ErrorFallback = ({ error }: ErrorFallbackProps) => {
    const [messages, setMessages] = useState<DisplayableErrorMessage[]>([]);

    const i18n = useI18n(defaultErrorFallbackI18n);
    const gwtContext = useGwt();

    useEffect(() => {
        setMessages(errorToMessages(error, i18n.defaultErrorMessage));
    }, [error, i18n.defaultErrorMessage]);

    useEffect(() => {
        gwtContext.notifyCanShow?.();
    }, [gwtContext]);

    return (
        <Fragment>
            {messages.map((info, index) => (
                <ErrorMessage
                    key={index}
                    {...info}
                    onClose={() =>
                        setMessages(oldMessages => {
                            return oldMessages.filter((_, itemIndex) => itemIndex !== index);
                        })
                    }
                />
            ))}
        </Fragment>
    );
};
