import React, { useEffect, useRef, useState } from 'react';
import { Spr_user_message_ot } from '@alcs/beans';
import { AlsSubmitButton } from '@alcs/core/components/AlsSubmitButton';
import { AlsFallback } from '@alcs/core/components/containers/AlsFallback';
import { defaultErrorFallbackI18n } from '@alcs/core/components/ErrorFallback';
import { FieldErrorAlert } from '@alcs/core/components/ErrorsAlert';
import { parseBoolean } from '@alcs/core/utils/TextUtils';
import { useService } from '@alcs/core/utils/useService';
import { useI18n } from '@alcs/i18n';
import { emptyServiceCallback, LoginService, SessionService } from '@alcs/services';
import { Alert, styled } from '@mui/material';
import { FormHelpers, ReactiveFormProvider, useForm } from '@reactive-forms/core';
import { Form } from '@reactive-forms/dom';
import { AxiosError } from 'axios';
import { useRouter } from 'next/dist/client/router';
import Link from 'next/link';

import { BooleanField } from '@alcs/core/components/fields/BooleanField';

import { PageContainer } from '../components/PageContainer';
import { PasswordField } from '../components/PasswordField';
import { StyledLink } from '../components/StyledLink';
import { UsernameField } from '../components/UsernameField';
import { useFileTypes } from '../SessionStorage/useFileTypes';
import { useLoginEmail } from '../SessionStorage/useLoginEmail';
import { useReceiver } from '../SessionStorage/useTransporter';
import {
    completedRegistrationTransporter,
    passwordResetErrorTransporter,
    registrationErrorTransporter,
    sessionExpiredErrorTransporter,
} from '../utils/Transporters';
import { useLogin } from '../utils/useLogin';
import { useLoginI18n } from '../utils/useLoginI18n';

type LoginFormValues = {
    email: string;
    password: string;
    rememberLogin: string;
};

const LOGIN_ERROR_CODES = {
    UNKNOWN_USER: 'error.user.unknown',
    AUTH_ERROR: 'error.auth.login',
};

const Container = styled(PageContainer, {
    label: 'LoginPageContainer',
})({
    minWidth: '190px',
    maxWidth: '295px',
    width: 'min(295px, calc(100vw - 160px))',
});

const LinkWrapper = styled('div', {
    label: 'LoginPageLinkWrapper',
})({
    marginTop: '10px',
    fontSize: '14px',
    textAlign: 'center',
});

const RegistrationLinkWrapper = styled('div', {
    label: 'LoginPageRegistrationLinkWrapper',
})({
    marginTop: '10px',
    display: 'flex',
    justifyContent: 'space-evenly',
});

const FieldWrapper = styled(Form, {
    label: 'LoginPageFieldWrapper',
})(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
}));

const LoginPage = () => {
    const i18n = useLoginI18n();
    const errorI18n = useI18n(defaultErrorFallbackI18n);
    const loginService = useService(LoginService, emptyServiceCallback);
    const router = useRouter();

    const { setEmail, email, isEmailLoaded } = useLoginEmail();
    const { setFileTypes } = useFileTypes();
    const login = useLogin();
    const [receivedCompletedReg, receivedCompletedRegValue] = useReceiver(completedRegistrationTransporter);
    const [receivedRegError, receivedRegErrorValue] = useReceiver(registrationErrorTransporter);
    const [resetPassErrReceived, resetPassError] = useReceiver(passwordResetErrorTransporter);
    const [receivedExpiredSessionError, receivedExpiredSessionErrorValue] = useReceiver(sessionExpiredErrorTransporter);

    const [isLoaded, setIsLoaded] = useState(false);
    const [showLoginAlert, setShowLoginAlert] = useState(false);

    const emailFieldRef = useRef<HTMLInputElement>(null);

    const handleLogin = async (values: LoginFormValues, helpers: FormHelpers<LoginFormValues>) => {
        helpers.setFieldError<LoginFormValues>(helpers.paths, {
            $error: undefined,
        });
        try {
            await login(values.email, values.password, '', '', parseBoolean(values.rememberLogin));
        } catch (error) {
            const message = (error as AxiosError<{ messages?: Spr_user_message_ot[] }>).response?.data.messages?.[0];
            const fieldError = {
                $error: message?.default_text ? message?.default_text : errorI18n.defaultErrorMessage,
            };

            if (!message) {
                return helpers.setFieldError<LoginFormValues>(helpers.paths, { ...fieldError });
            }

            switch (message.code) {
                case LOGIN_ERROR_CODES.UNKNOWN_USER:
                    helpers.setFieldError<LoginFormValues>(helpers.paths, { ...fieldError });
                    helpers.setFieldError<string>(helpers.paths.email, { ...fieldError });
                    break;
                case LOGIN_ERROR_CODES.AUTH_ERROR:
                    helpers.setFieldError<LoginFormValues>(helpers.paths, { ...fieldError });
                    helpers.setFieldError<string>(helpers.paths.password, { ...fieldError });
                    break;
                default:
                    helpers.setFieldError<LoginFormValues>(helpers.paths, { ...fieldError });
            }
        }
    };

    const formBag = useForm<LoginFormValues>({
        initialValues: {
            email: '',
            password: '',
            rememberLogin: 'N',
        },
        onSubmit: handleLogin,
    });

    const { paths, getFieldValue, setFieldValue } = formBag;

    useEffect(() => {
        if (isEmailLoaded && email && emailFieldRef.current) {
            emailFieldRef.current.value = email;
            setFieldValue(paths.email, email);
        }
    }, [email, isEmailLoaded, paths.email, setFieldValue]);

    useEffect(() => {
        async function loadServices() {
            const sessionServ = new SessionService(emptyServiceCallback, 'loginSessionService');
            const sessionData = await sessionServ.preloadSessionData(false);
            setFileTypes(sessionData.classifierMap.ALLOWED_FILE_EXTS);
            const key = await loginService.hasSessionKey();
            if (key) {
                try {
                    const session = await loginService.create_session_by_key();
                    if (session.redirectUrl && session.sessionCompany) {
                        router.push({
                            pathname: session.redirectUrl,
                            hash: window.location.hash,
                        });
                    }
                } catch (error) {
                    setShowLoginAlert(true);
                }
            }
            setIsLoaded(true);
        }

        loadServices();
    }, [loginService, router, setFileTypes]);

    const setSessionEmail = () => {
        setEmail(getFieldValue(paths.email));
    };

    if (!isLoaded) {
        return <AlsFallback />;
    }

    return (
        <Container title={i18n.signIn} backHref="/alcs/Public.html" backTitle={i18n.goToFracht}>
            <ReactiveFormProvider formBag={formBag}>
                <FieldWrapper>
                    <FieldErrorAlert path={paths} useTouched={false} />
                    {receivedCompletedReg && receivedCompletedRegValue && (
                        <Alert severity="success">{i18n.registrationCompleted}</Alert>
                    )}
                    {receivedRegError && receivedRegErrorValue && (
                        <Alert severity="error">{receivedRegErrorValue}</Alert>
                    )}

                    {receivedExpiredSessionError && receivedExpiredSessionErrorValue && (
                        <Alert severity="error">{receivedExpiredSessionErrorValue}</Alert>
                    )}
                    {resetPassErrReceived && resetPassError && <Alert severity="error">{resetPassError}</Alert>}
                    {showLoginAlert && (
                        <Alert severity="warning" onClose={() => setShowLoginAlert(false)}>
                            {i18n.loginWarning}
                        </Alert>
                    )}

                    <UsernameField ref={emailFieldRef} name={paths.email} label={i18n.username} autoFocus />
                    <PasswordField name={paths.password} label={i18n.password} />
                    <BooleanField name={paths.rememberLogin} label={i18n.staySignedIn} />
                    <AlsSubmitButton variant="contained" color="primary" type="submit">
                        {i18n.signIn}
                    </AlsSubmitButton>
                </FieldWrapper>
            </ReactiveFormProvider>

            <LinkWrapper>
                <StyledLink as={Link} href="/alcs/auth/forgot" onClick={setSessionEmail}>
                    {i18n.forgotMyPassword}
                </StyledLink>
                <RegistrationLinkWrapper>
                    <StyledLink as={Link} href="/alcs/auth/register">
                        {i18n.newCompany}
                    </StyledLink>
                    <StyledLink as={Link} href="/alcs/auth/user-register">
                        {i18n.newUser}
                    </StyledLink>
                </RegistrationLinkWrapper>
            </LinkWrapper>
        </Container>
    );
};

export default LoginPage;
