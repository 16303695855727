import React, { forwardRef, useMemo } from 'react';
import mergeRefs from '@alcs/core/utils/mergeRefs';
import { commonsDefaultI18n, useI18n } from '@alcs/i18n';
import { Icon, InputAdornment } from '@mui/material';
import { useUncontrolledField } from '@reactive-forms/dom';
import { Pxth } from 'pxth';
import * as yup from 'yup';

import { StyledTextField } from '@alcs/core/components/fields/Fields';

export type UsernameFieldProps = {
    name: Pxth<string>;
    label: string;
    autoFocus?: boolean;
};

export const UsernameField = forwardRef(
    ({ name, label, autoFocus }: UsernameFieldProps, ref: React.Ref<HTMLInputElement>) => {
        const commonsI18n = useI18n(commonsDefaultI18n);
        const usernameValidationSchema = useMemo(
            () => yup.string().required(commonsI18n.required),
            [commonsI18n.required],
        );

        const [{ ref: inputRef, ...bag }, { error, touched }] = useUncontrolledField({
            name,
            schema: usernameValidationSchema,
        });

        return (
            <StyledTextField
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <Icon color="action">person_rounded_icon</Icon>
                        </InputAdornment>
                    ),
                }}
                inputProps={{
                    style: {
                        paddingLeft: 5,
                        width: error?.$error ? '78%' : '100%',
                    },
                }}
                error={error?.$error}
                touched={touched?.$touched}
                label={label}
                variant="standard"
                autoFocus={autoFocus}
                inputRef={mergeRefs(inputRef, ref)}
                {...bag}
            />
        );
    },
);
