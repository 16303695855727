import { useSessionStorage } from './useSessionStorage';

const emailSessionStorageKey = 'email';

export const useLoginEmail = () => {
    const { value, setValue, isLoaded } = useSessionStorage<string>(emailSessionStorageKey);

    return {
        email: value,
        setEmail: setValue,
        isEmailLoaded: isLoaded,
    };
};
